import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import t from "module/translations";
import Typography from "@mui/material/Typography";
import nl2br from "toolkit/nl2br";
import { MaintenanceTaskProcessStepInterface } from "module/maintenanceTaskProcessStep/types";
import MaintenanceTaskProcessStepMeasureFieldContainer from "module/maintenanceTaskProcessStep/enrichedComponent/measureValueField/MaintenanceTaskProcessStepMeasureFieldContainer";
import MaintenanceTaskProcessStepCheckResultFieldContainer from "module/maintenanceTaskProcessStep/enrichedComponent/checkResultField/MaintenanceTaskProcessStepCheckResultFieldContainer";
import MaintenanceTaskProcessStepActionPerformedFieldContainer from "module/maintenanceTaskProcessStep/enrichedComponent/actionPerformedField/MaintenanceTaskProcessStepActionPerformedFieldContainer";
import XLabelField from "component/labelField/XLabelField";
import MaintenanceTaskProcessStepRemarksFieldContainer from "module/maintenanceTaskProcessStep/enrichedComponent/remarksField/MaintenanceTaskProcessStepRemarksFieldContainer";
import MaintenanceTaskProcessStepAttachmentsSummaryFieldContainer from "module/maintenanceTaskProcessStepAttachment/enrichedComponent/summaryField/MaintenanceTaskProcessStepAttachmentsSummaryFieldContainer";
import ArticleTextContainer from "module/article/enrichedComponent/ArticleTextContainer";
import MyMaintenanceTaskProcessStepQuickFixContainer from "../../../maintenanceTaskProcessStepQuickFix/enrichedComponent/quickFix/MyMaintenanceTaskProcessStepQuickFixContainer";
import MyMaintenanceTaskProcessStepCorrectiveContainer from "../../../maintenanceTaskProcessStepCorrective/enrichedComponent/corrective/MyMaintenanceTaskProcessStepCorrectiveContainer";

const highlightColor = "rgb(253, 237, 237)";

const useStyles = makeStyles({
  mainRow: {
    "& > *": {
      borderBottom: (props: { isOpen: boolean; isHighlighted: boolean }) =>
        props.isOpen ? "unset" : "",
      background: (props: { isOpen: boolean; isHighlighted: boolean }) =>
        props.isHighlighted ? highlightColor : "",
    },
  },
  secondRow: {
    "& > *": {
      background: (props: { isOpen: boolean; isHighlighted: boolean }) =>
        props.isHighlighted ? highlightColor : "",
    },
  },
  component: {
    whiteSpace: "nowrap",
    width: "10px",
  },
  measure: {
    width: "150px",
  },
  checkResult: {
    width: "265px",
  },
  remarks: {
    width: "254px",
  },
  actionPerformed: {
    width: "254px",
  },
  measureValue: {
    "& input": {
      textAlign: "right",
    },
  },
  fix: {
    margin: "5px 0",
    display: "flex",
    justifyContent: "space-between",
  },
});

interface Props {
  processStep: MaintenanceTaskProcessStepInterface;
  isOpen: boolean;
  toggle: () => void;
  isActionPerformedAvailable: boolean;
  isCheckedResultAvailable: boolean;
  isQuickFixAvailable: boolean;
  isCorrectiveFixAvailable: boolean;
  isToolAndEquipmentAvailable: boolean;
  isHighlighted: boolean;
}

const MaintenanceTaskProcessStepListRow: React.FC<Props> = ({
  processStep,
  isOpen,
  toggle,
  isActionPerformedAvailable,
  isCheckedResultAvailable,
  isQuickFixAvailable,
  isCorrectiveFixAvailable,
  isToolAndEquipmentAvailable,
  isHighlighted,
}) => {
  const classes = useStyles({ isOpen, isHighlighted });

  const descriptionColspan = isToolAndEquipmentAvailable ? 2 : 3;

  return (
    <>
      <TableRow
        hover
        className={classes.mainRow}
        id={`maintenance-task-process-step-${processStep.id}`}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={toggle}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" colSpan={2}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: nl2br(processStep.name),
            }}
          />
        </TableCell>
        <TableCell align="left" className={classes.component}>
          {processStep.component.map((singleComponent) => (
            <div>{singleComponent}</div>
          ))}
        </TableCell>
        <TableCell align="left" className={classes.measure}>
          <MaintenanceTaskProcessStepMeasureFieldContainer />
        </TableCell>
        {isCheckedResultAvailable && (
          <TableCell align="left" className={classes.checkResult}>
            <div>
              <MaintenanceTaskProcessStepCheckResultFieldContainer />
            </div>
            {(isQuickFixAvailable || isCorrectiveFixAvailable) && (
              <div className={classes.fix}>
                {isQuickFixAvailable && (
                  <MyMaintenanceTaskProcessStepQuickFixContainer />
                )}
                {isCorrectiveFixAvailable && (
                  <MyMaintenanceTaskProcessStepCorrectiveContainer />
                )}
              </div>
            )}
          </TableCell>
        )}
        {isActionPerformedAvailable && (
          <TableCell align="left" className={classes.actionPerformed}>
            <div>
              <MaintenanceTaskProcessStepActionPerformedFieldContainer />
            </div>
          </TableCell>
        )}
      </TableRow>
      {isOpen && (
        <TableRow className={classes.secondRow}>
          <TableCell
            align="left"
            colSpan={descriptionColspan}
            dangerouslySetInnerHTML={{
              __html: nl2br(processStep.description),
            }}
          />
          {isToolAndEquipmentAvailable && (
            <TableCell align="left">
              <ArticleTextContainer {...{ value: processStep.articleId }} />
            </TableCell>
          )}
          <TableCell align="left">
            <XLabelField
              {...{
                caption: t.maintenanceTask.processStepTypeText,
                label: processStep.type,
              }}
            />
          </TableCell>
          <TableCell align="left">
            <MaintenanceTaskProcessStepAttachmentsSummaryFieldContainer />
          </TableCell>
          <TableCell align="left" className={classes.checkResult}>
            <MaintenanceTaskProcessStepRemarksFieldContainer />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default React.memo(MaintenanceTaskProcessStepListRow);
