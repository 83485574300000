import axios from "axios";
import { ApiMaintenanceTaskInterface } from "api/maintenanceTask/types";
import { MaintenanceTaskFilters } from "pages/maintenanceTaskList/types";
import { mapApiMaintenanceTaskToApp } from "module/maintenanceTask/helper/mapApiMaintenanceTaskToApp";
import { MyMaintenanceTaskInterface } from "../../module/maintenanceTask/types";

interface ApiGetMaintenanceTasksResponse {
  items: ApiMaintenanceTaskInterface[];
}

export const mapApiResponse = (
  rawData: ApiGetMaintenanceTasksResponse
): MyMaintenanceTaskInterface[] =>
  rawData.items.map(mapApiMaintenanceTaskToApp);

const getMaintenanceTasksByIds = async (
  filters: Pick<MaintenanceTaskFilters, "types" | "definitionTypes"> & {
    ids: number[];
  }
): Promise<MyMaintenanceTaskInterface[]> => {
  const response = await axios.get(
    `/api/maintenance-tasks?` +
      `types=${filters.types.join()}&` +
      `definitionTypes=${filters.definitionTypes.join()}&` +
      `ids=${filters.ids.join()}&`
  );
  return mapApiResponse(response.data);
};

export default getMaintenanceTasksByIds;
