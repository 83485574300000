import React, { useContext, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  selectSystem,
  selectSystemConfiguration,
  setSystemConfiguration,
} from "module/auth/redux/authSlice";
import OnlineDetectorContext from "module/onlineDetector/OnlineDetectorContext";
import moment from "moment";
import getSystem from "api/auth/getSystem";

const KeepSystemConfigurationFresh: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOnline } = useContext(OnlineDetectorContext);
  const { shortName } = useAppSelector(selectSystem);
  const configuration = useAppSelector(selectSystemConfiguration);

  const shouldRefreshSystemConfig = useCallback((): boolean => {
    const { dateOfRetrievingData } = configuration;

    return (
      dateOfRetrievingData == null ||
      moment(dateOfRetrievingData).add(1, "hour") < moment()
    );
  }, [configuration]);

  const refreshSystemConfig = useCallback((): void => {
    async function refresh() {
      const system = await getSystem(shortName);
      dispatch(setSystemConfiguration(system));
    }
    refresh();
  }, [dispatch, shortName]);

  if (isOnline) {
    if (shouldRefreshSystemConfig()) {
      refreshSystemConfig();
    }
  }

  return null;
};

export default React.memo(KeepSystemConfigurationFresh);
