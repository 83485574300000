import React from "react";
import AppInfo from "pages/appInfo/AppInfo";
import { useAppSelector } from "redux/hooks";
import { selectDeviceId } from "module/device/redux/deviceSlice";
import GitInfo from "react-git-info/macro";
import useDeleteAllUserData from "module/staff/useDeleteAllUserData";
import { selectUser } from "module/auth/redux/authSlice";

const AppInfoContainer: React.FC = () => {
  const deviceId = useAppSelector(selectDeviceId);
  const gitCommitHash = GitInfo().commit.hash;
  const deleteAllUserData = useDeleteAllUserData();
  const staffId = useAppSelector(selectUser).id;

  return (
    <AppInfo {...{ deviceId, gitCommitHash, deleteAllUserData, staffId }} />
  );
};

export default React.memo(AppInfoContainer);
