import axios from "axios";

interface ApiGetMaintenanceTasksResponse {
  items: number[];
}

export const mapApiResponse = (
  rawData: ApiGetMaintenanceTasksResponse
): number[] => rawData.items;

const getMyBlockedMaintenanceTaskIds = async (): Promise<number[]> => {
  const response = await axios.get(`/api/my-blocked-maintenance-task-ids`);
  return mapApiResponse(response.data);
};

export default getMyBlockedMaintenanceTaskIds;
