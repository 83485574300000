import React, { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import { selectSystem, selectUser } from "module/auth/redux/authSlice";
import { selectDeviceId } from "module/device/redux/deviceSlice";
import * as SentryLib from "@sentry/react";

const Sentry: React.FC = () => {
  const user = useAppSelector(selectUser);
  const system = useAppSelector(selectSystem);
  const deviceId = useAppSelector(selectDeviceId);

  useEffect(() => {
    SentryLib.setUser({
      id: `${user.id}`,
      username: `${user.firstName} ${user.lastName}`,
    });
    SentryLib.setTag("system", system.shortName);
    SentryLib.setTag("deviceId", deviceId);
  }, [user.firstName, user.lastName, system.shortName, deviceId, user.id]);
  return null;
};

export default Sentry;
