import { useContext, useCallback } from "react";
import { useAppDispatch } from "redux/hooks";
import { setSystem, setSystemConfiguration } from "module/auth/redux/authSlice";
import * as Sentry from "@sentry/react";
import getSystem from "api/auth/getSystem";
import FullScreenLoaderContext from "module/fullScreenLoader/context/FullScreenLoaderContext";

interface Result {
  changeSystem: (systemIdentifier: string) => void;
}

const useSystemChange = (): Result => {
  const dispatch = useAppDispatch();
  const { turnOn: turnLoaderOn } = useContext(FullScreenLoaderContext);

  const changeSystem = useCallback(
    async (systemIdentifier: string) => {
      turnLoaderOn();
      const system = await getSystem(systemIdentifier);
      dispatch(setSystemConfiguration(system));
      dispatch(setSystem(systemIdentifier));
      Sentry.setTag("system", systemIdentifier);
      global.location.href = "/";
    },
    [dispatch, turnLoaderOn]
  );

  return { changeSystem };
};

export default useSystemChange;
