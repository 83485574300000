import React, { useCallback, useEffect, useMemo, useState } from "react";
import { difference } from "lodash";
import MyMaintenanceTaskListContainer from "pages/myMaintenanceTaskList/MyMaintenanceTaskListContainer";
import SortContextProvider from "module/sort/context/SortContextProvider";
import { SortDirection } from "module/sort/types";
import {
  MaintenanceTaskDefinitionType,
  MaintenanceTaskSortProperty,
} from "pages/maintenanceTaskList/types";
import { useAppSelector } from "redux/hooks";
import selectEntitiesByTypes from "module/staff/redux/selector/selectEntitiesByTypes";
import { EntityIdentifier, EntityType } from "module/entity/types";
import { filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep } from "module/maintenanceTask/helper/filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep";
import * as Sentry from "@sentry/react";
import { selectSystem } from "../../module/auth/redux/authSlice";
import getMyBlockedMaintenanceTaskIds from "../../api/maintenanceTask/getMyBlockedMaintenanceTaskIds";
import useEntityDownload from "../../module/entity/useEntityDownload";
import getMaintenanceTasksByIds from "../../api/maintenanceTask/getMaintenanceTasksByIds";
import { supportedTypes } from "../../module/maintenanceTask/helper/supportedTypes";
import useOnlineDetector from "../../module/onlineDetector/useOnlineDetector";
import { MyMaintenanceTaskInterface } from "../../module/maintenanceTask/types";

const MyMaintenanceTaskListPage: React.FC = () => {
  const system = useAppSelector(selectSystem);
  const wholeStore = useAppSelector((state) => state);
  const { isOnline } = useOnlineDetector();
  const myMaintenanceTasks = useAppSelector(
    selectEntitiesByTypes([EntityType.maintenanceTask])
  ) as MyMaintenanceTaskInterface[];
  const [diff, setDiff] = useState<number[]>([]);

  const maintenanceTasks = useMemo(
    () =>
      myMaintenanceTasks
        .filter(
          (myMaintenanceTask) =>
            myMaintenanceTask.data.definitionType == null ||
            myMaintenanceTask.data.definitionType ===
              MaintenanceTaskDefinitionType.system
        )
        .map(({ data }) => data)
        .filter(
          filterOutMaintenanceTaskCreatedForOtherMaintenanceTaskProcessStep
        ),
    [myMaintenanceTasks]
  );
  const { download } = useEntityDownload();

  useEffect(() => {
    if (system.shortName === "OAC") {
      const minStaffData = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const staffId of Object.keys(wholeStore.staffData)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        minStaffData[`${staffId}`] = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const systemShortName of Object.keys(
          wholeStore.staffData[Number(staffId)]
        )) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          minStaffData[`${staffId}`][`${systemShortName}`] = JSON.stringify(
            Object.keys(
              wholeStore.staffData[Number(staffId)][systemShortName].entities
            )
          );
        }
      }

      Sentry.withScope((scope) => {
        scope.setLevel("info");

        scope.setExtra("store.auth.user.id", wholeStore.auth.user?.id);
        scope.setExtra("store.device", wholeStore.device);
        scope.setExtra("store.minStaffData", minStaffData);

        Sentry.captureMessage("My maintenance task page load");
      });
    }
  }, [system.shortName, wholeStore]);

  const maintenanceTasksIds = useMemo(
    () => maintenanceTasks.map((maintenanceTask) => Number(maintenanceTask.id)),
    [maintenanceTasks]
  );

  // it's a workaround for the issue with disappeared maintenance tasks
  // app asks for blocked maintenance tasks ids and downloads them if they are not in the store
  const calculateDiff = useCallback(async () => {
    const blockedMaintenanceTaskIds = await getMyBlockedMaintenanceTaskIds();
    setDiff(difference(blockedMaintenanceTaskIds, maintenanceTasksIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceTasksIds.length]);

  useEffect(() => {
    if (isOnline && system.shortName === "OAC") {
      calculateDiff();
    }
  }, [calculateDiff, isOnline, system.shortName]);

  const downloadBlockedMaintenanceTask = useCallback(
    async (ids: number[]) => {
      const maintenanceTasksToDownload = await getMaintenanceTasksByIds({
        ids,
        types: supportedTypes,
        definitionTypes: [MaintenanceTaskDefinitionType.system],
      });
      const maintenanceTaskMap = maintenanceTasksToDownload.reduce(
        (result, maintenanceTask) => {
          result.set(maintenanceTask.entityIdentifier, maintenanceTask);
          return result;
        },
        new Map<EntityIdentifier, MyMaintenanceTaskInterface>()
      );

      await download(maintenanceTaskMap);
    },
    [download]
  );

  useEffect(() => {
    if (isOnline && diff.length > 0) {
      downloadBlockedMaintenanceTask(diff);
    }
  }, [diff, downloadBlockedMaintenanceTask, isOnline, maintenanceTasksIds]);

  return (
    <SortContextProvider
      {...{
        initialValue: {
          fieldName: MaintenanceTaskSortProperty.scheduled,
          direction: SortDirection.asc,
        },
      }}
    >
      <MyMaintenanceTaskListContainer
        {...{
          maintenanceTasks,
        }}
      />
    </SortContextProvider>
  );
};

export default React.memo(MyMaintenanceTaskListPage);
